body {
  font-family: Rubik, sans-serif;
  font-weight: 300;
}

.layout {
  height: 75%;
  width: 75%;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

/*# sourceMappingURL=index.10cde906.css.map */
